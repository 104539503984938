<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-6 rounded-lg bg-white p-4">
      <div class="border-b border-gray-200 py-5 sm:px-6">
        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Pengiriman Retur Barang ke Pusat
          </h3>
        </div>
      </div>

      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="flex flex-col">
          <div class="flex items-center justify-end space-x-2">
            <div class="relative mt-1 rounded-md shadow-sm">
              <div>
                <select
                  v-model="is_shipped"
                  @change="filterByCompleted"
                  class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                >
                  <option :value="null">Semua</option>
                  <option :value="true">Terkirim</option>
                  <option :value="false">Belum dikirim</option>
                </select>
              </div>
            </div>
            <base-search
              placeholder="Cari Kode"
              v-model="search"
              v-on:input="debounceSearch"
            />
          </div>
          <StockMovementTable
            @page-change="onPageChangeOrder"
            @click-row="redirectToDetail"
            is-shipped
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';
import StockMovementTable from '@/components/stock-movement/stock-movement-table.vue';

export default {
  name: 'PengirimanReturKePusat',
  components: {
    BaseSearch,
    StockMovementTable,
  },

  data() {
    return {
      search: null,
      is_shipped: false,
      filter: {
        page: {
          size: 5,
          number: 1,
        },
        search: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      isLoading: 'stock_movements/getLoading',
    }),
    stockMovementParams() {
      return {
        pageNumber: this.filter.page.number,
        pageSize: 5,
        is_valid_for_shipment: true,
        is_shipped: this.is_shipped,
        search: this.filter.search,
        destination_office_category: 'Supplier',
        origin_office_category: 'Pusat',
        'filter[movement_type]': 'main-return',
        isMainReturn: true,
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      sendStockMovement: 'stock_movements/sendStockMovement',
    }),
    debounceSearch: debounce(function () {
      this.fetchStockMovements(this.stockMovementParams);
    }, 300),
    onPageChangeOrder(page) {
      this.filter.page.number = page;
      this.fetchStockMovements(this.stockMovementParams);
    },
    filterByCompleted() {
      this.fetchStockMovements(this.stockMovementParams);
    },
    redirectToDetail(data) {
      this.$router.push(`/gudang/pengiriman/retur-ke-supplier/${data.id}`);
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    validate(data) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: data.relationships['stock-movements'].data[0].id,
          attributes: {
            taken_by: 'Superadmin',
          },
        },
      };
      this.sendStockMovement(payload).then((response) => {
        if (response) {
          this.fetchStockMovements(this.stockMovementParams);
        }
        this.openDetail = false;
      });
    },
  },
  created() {
    this.fetchStockMovements(this.stockMovementParams);
  },
};
</script>
