var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getStockMovement.data.attributes.is_shipped)?_c('BaseCard',{staticClass:"mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8",attrs:{"title":"Informasi Pengeluaran","description":"Detail Pengeluaran yang akan dikirim ke Stockist"}},[(
      _vm.getRelationships(
        _vm.getStockMovement,
        _vm.getStockMovement.data.relationships['order'].data?.id
      )?.attributes?.order_shipment === 'delivery'
    )?_c('div',[_c('div',{staticClass:"mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12"},[_c('div',{staticClass:"sm:col-span-12"},[_c('div',{staticClass:"field-inset-default w-full bg-gray-100"},[_c('label',{staticClass:"block text-xs font-bold text-gray-700",attrs:{"for":"kode_po"}},[_vm._v(" Nomor Resi ")]),_c('p',{staticClass:"block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"},[_vm._v(" "+_vm._s(_vm.getStockMovement.data.attributes.code)+" ")])])])])]):(
      _vm.getRelationships(
        _vm.getStockMovement,
        _vm.getStockMovement.data.relationships['order'].data?.id
      )?.attributes?.order_shipment === 'pickup'
    )?_c('div',[_c('div',{staticClass:"mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12"},[_c('div',{staticClass:"sm:col-span-12"},[_c('base-input',{attrs:{"label":"Diambil Oleh","name":"taken_by","id":"taken_by","inset":"","disabled":"","value":_vm.getStockMovement.data.attributes.taken_by}})],1)])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }