import { debounce } from 'debounce';
import { mapActions, mapGetters } from 'vuex';

export const stockMovementRead = {
  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      getStockMovement: 'stock_movements/getStockMovement',
    }),
  },
  methods: {
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    // actions
    async readStockMovements({ params } = {}) {
      await this.fetchStockMovements({
        ...(this.readStockMovementsParams ?? {}),
        ...params,
      });
    },
    async viewStockMovement(stockMovementId, { params } = {}) {
      await this.fetchStockMovement({
        id: stockMovementId,
        ...(this.viewStockMovementParams ?? {}),
        ...params,
      });

      if (this.onStockMovementViewed) {
        await this.onStockMovementViewed();
      }
    },

    // helpers
    getStockMovementIncluded(simpleStockMovement) {
      return this.getSingleIncludedType(
        'stock-movement',
        this.getStockMovements,
        simpleStockMovement
      );
    },

    // events
    onCreated() {
      this.readStockMovements();
    },
    onReload() {
      this.readStockMovements();
    },
    onReloadDebounce: debounce(function () {
      this.readStockMovements();
    }, 300),
    onPageChanged(page) {
      this.readStockMovements({
        params: {
          [`page[${page === 'next' ? 'after' : 'before'}]`]:
            this.getStockMovements.meta.page[page === 'next' ? 'from' : 'to'],
        },
      });
    },
    onViewStockMovement(stockMovementOrSimpleStockMovement) {
      if (
        stockMovementOrSimpleStockMovement.type === 'simple-stock-movements'
      ) {
        this.viewStockMovement(
          this.getStockMovementIncluded(stockMovementOrSimpleStockMovement).id
        );
      }
    },
  },
};
