<template>
  <OrderDetailModal
    title="Detail Order Barang"
    description="Data Order Barang"
    :visible="visible"
    :order="getOrder.data"
    :editable="false"
    :deleteable="false"
    :printable="false"
    :with-payments="false"
    :custom-action="true"
    withStockMovement
    @detail-stock-movement="onDetailStockMovement"
    @close="handleClose"
    @refresh="handleRefresh"
  >
    <template #detail="{ order }">
      <dl class="flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">No PO</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ order.attributes.destination_code }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Tanggal</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ dayjs(order.attributes.updatedAt).format('ll LT') }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Kode Kantor</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              order.relationships['origin-office'].data
                ? getRelationships(
                    getOrder,
                    order.relationships['origin-office'].data.id
                  )?.attributes.code
                : '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">Dibuat Oleh</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ order.attributes.created_by?.office?.name }}/{{
              order.attributes.created_by?.user?.name
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">Nama Supplier</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.name ?? '-'
                : ''
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Alamat Supplier 1</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.address ?? '-'
                : ''
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Alamat Supplier 2</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.address2 ?? '-'
                : ''
            }}
          </dd>
        </div>
      </dl>
    </template>

    <template #order-details="{ orderDetails, order }">
      <Datatable :paginated="false" :footer="false" class="mt-4">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                KODE BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                JUMLAH
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                BELUM DITERIMA
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                DIKIRIM
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                BERAT
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TOTAL HARGA
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TOTAL BERAT
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <tr
              class="bg-white"
              v-for="(barang, index) in orderDetails"
              :key="index"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{ barang.attributes.product_code }} ({{ barang.attributes.product_type === 'free' ? 'Promo' : 'Reguler' }})
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_qty | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_not_received | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ getOrderProductShipped(barang) | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_weight | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.total_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.total_weight | toCurrency }}
              </td>
            </tr>
          </tbody>
          <!-- Informasi Total -->
          <tfoot>
            <tr class="bg-white">
              <td
                colspan="5"
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                Total
              </td>

              <td
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                {{ order.attributes.grand_total_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                {{ order.attributes.grand_total_weight }}
              </td>
            </tr>
          </tfoot>
        </template>
      </Datatable>
    </template>

    <template #action="{ order }">
      <slot name="action" :order="order">
        <div class="flex justify-end space-x-2">
          <button
            @click="handleValidate(order)"
            v-if="
              !order.attributes.is_shipped &&
              order.relationships['stock-movements'].data.length > 0
            "
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            Validasi
          </button>
          <router-link
            v-else-if="!order.attributes.is_shipped"
            :to="'barang-dari-supplier/' + order.id"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
              />
            </svg>
            Kirim
          </router-link>
          <base-button @click="handleClose" type="button"> Tutup </base-button>
        </div>
      </slot>
    </template>
  </OrderDetailModal>
</template>

<script>
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import Datatable from '@/components/base/Datatable';
import BaseButton from '@/components/base/BaseButton';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
  components: {
    OrderDetailModal,
    Datatable,
    BaseButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    redirectOnClose: {
      type: Boolean,
      default: false,
    },
    redirectTo: {
      type: null,
      default: null,
    },
  },
  data() {
    return {};
  },
  emits: ['close', 'refresh', 'detail-stock-movement'],
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
  },
  methods: {
    ...mapActions({
      fetchStockMovement: 'stock_movements/fetchStockMovement',
      sendStockMovement: 'stock_movements/sendStockMovement',
    }),
    dayjs,
    handleClose() {
      if (this.redirectOnClose) {
        this.$router.push(
          this.redirectTo ?? '/gudang/pengiriman/barang-dari-supplier'
        );
      } else {
        this.$emit('close');
      }
    },
    handleRefresh() {
      if (this.redirectOnClose) {
        this.$router.push(
          this.redirectTo ?? '/gudang/pengiriman/barang-dari-supplier'
        );
      } else {
        this.$emit('refresh');
      }
    },
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    getOrderProductShipped(product) {
      const stockMovements = this.getOrder.included.filter(
        (it) => it.type === 'stock-movements'
      );

      return stockMovements?.length
        ? stockMovements[0].attributes.product_summaries[
            product.attributes.product_code
          ].shipped_product_qty
        : 0;
    },
    handleValidate(order) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: order.relationships['stock-movements'].data[0].id,
          attributes: {
            taken_by: 'Superadmin',
          },
        },
      };
      this.sendStockMovement(payload).then((response) => {
        if (response) {
          this.handleRefresh();
        }
      });
    },
    async handleDetailStockMovement(id) {
      const res = await this.fetchStockMovement({ id });

      if (res) {
        this.openDetail = false;
        this.openDetailStockMovement = true;
      }
    },
    onDetailStockMovement(stockMovementId) {
      this.$emit('detail-stock-movement', stockMovementId);
    },
  },
};
</script>
