<template>
  <BaseCard
    title="Informasi Kemasan"
    description="Daftar kemasan yang akan dikirim ke Stockist"
  >
    <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
      <div class="sm:col-span-12">
        <div class="field-inset-default w-full bg-gray-100">
          <label for="kode_po" class="block text-xs font-bold text-gray-700">
            Nama Kemasan
          </label>
          <p
            class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
          >
            {{ getStockMovement.data.attributes.code }}
          </p>
        </div>
      </div>
    </div>
    <Datatable :paginated="false" :footer="false" class="mt-2">
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Kode Koli
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Jumlah Produk
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Aksi
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody>
          <tr
            class="bg-white"
            v-for="(koli, index) in getStockMovement.data.attributes.packets"
            :key="index"
          >
            <td
              @click="detailModal(koli)"
              class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
            >
              {{ koli.name }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ koli.products.length }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <div class="m-auto flex space-x-2">
                <button type="button" @click="printKoli(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <!-- Ketrigger ketika printKoli diklik -->
                <PrintKoli :koli="koli" ref="printRef" />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </Datatable>
    <!-- Detail Koli -->
    <ModalDetailKoli
      :visible="openDetail"
      :packet="detailKoli"
      @close="openDetail = !openDetail"
    />
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Datatable from '@/components/base/Datatable';
import PrintKoli from '@/components/PrintKoli.vue'; // ini cmn bisa 1 koli
import ModalDetailKoli from '@/components/pengiriman/modal-detail-koli.vue';

export default {
  name: 'DetailPengiriman',

  components: {
    Datatable,
    PrintKoli,
    ModalDetailKoli,
  },
  props: {
    viewAs: {
      default: 'stockist',
    },
  },
  data() {
    return {
      openDetail: false,
      detailKoli: {},
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
    }),
    getOfficeLabel() {
      const mapOffice = {
        stockist: 'Stockist',
        member: 'Mitra',
        pusat: 'Pusat',
        'stockist-center': 'Stockist Center',
        pelayanan: 'Kantor Pelayanan',
      };
      return mapOffice[this.viewAs];
    },
  },
  methods: {
    dayjs,
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    printKoli(index) {
      // 1. manggil printRef
      // 2. ngetrigger child method yg bernama generateReport
      this.$refs.printRef[index].generateReport();
    },
    detailModal(val) {
      this.openDetail = !this.openDetail;
      this.detailKoli = val;
      this.$emit('click-row', val);
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
