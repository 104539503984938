import { render, staticRenderFns } from "./detail-pengeluaran-card.vue?vue&type=template&id=bc278b04&scoped=true"
import script from "./detail-pengeluaran-card.vue?vue&type=script&lang=js"
export * from "./detail-pengeluaran-card.vue?vue&type=script&lang=js"
import style0 from "./detail-pengeluaran-card.vue?vue&type=style&index=0&id=bc278b04&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc278b04",
  null
  
)

export default component.exports