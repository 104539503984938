<template>
  <BaseCard :title="title">
    <div class="py-4">
      <dl class="flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">No Pengemasan</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getStockMovement.data.attributes.code }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">No Faktur</dt>
          <dd
            v-if="getStockMovement.data.relationships.order.data?.id"
            class="mt-1 text-sm text-gray-900"
          >
            {{
              getRelationships(
                getStockMovement,
                getStockMovement.data.relationships.order.data.id
              ).attributes.origin_code
            }}
            /
            {{
              getRelationships(
                getStockMovement,
                getStockMovement.data.relationships.order.data.id
              ).attributes.destination_code
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">Tanggal Pengemasan</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              dayjs(getStockMovement.data.attributes.updatedAt).format('ll LT')
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Kode {{ getOfficeLabel }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ destinationOffice.attributes?.code }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Nama {{ getOfficeLabel }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ destinationOffice.attributes?.name }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Alamat {{ getOfficeLabel }} 1
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ destinationOffice.attributes?.address }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Alamat {{ getOfficeLabel }} 2
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ destinationOffice.attributes?.address2 }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-sm font-bold text-gray-700">
            Nomor Telepon {{ getOfficeLabel }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getStockMovement,
                getOrder.relationships['destination-office'].data?.id
              )?.attributes?.phone ?? '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div
          v-if="getOrder.attributes.order_shipment == 'delivery'"
          class="w-full"
        >
          <dt class="text-sm font-bold text-gray-700">Dikirim ke Alamat</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getOrder.attributes.shipment_address }},
            {{
              getOrder.relationships['village']?.data
                ? getRelationships(
                    getStockMovement,
                    getOrder.relationships['village'].data.id
                  )?.attributes.name
                : '-'
            }},
            {{
              getOrder.relationships['district']?.data
                ? getRelationships(
                    getStockMovement,
                    getOrder.relationships['district'].data.id
                  )?.attributes.name
                : '-'
            }},
            {{
              getOrder.relationships['city']?.data
                ? getRelationships(
                    getStockMovement,
                    getOrder.relationships['city'].data.id
                  )?.attributes.name
                : '-'
            }},
            {{
              getOrder.relationships['province']?.data
                ? getRelationships(
                    getStockMovement,
                    getOrder.relationships['province'].data.id
                  )?.attributes.name
                : '-'
            }}
          </dd>
        </div>
      </dl>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'DetailPengiriman',

  components: {
    // Your child components here
  },
  props: {
    viewAs: {
      default: 'stockist',
    },
    title: {
      default: 'Detail Pengiriman',
    },
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
    }),
    getOrder() {
      return this.getStockMovement.included?.find((it) => it.type == 'orders');
    },
    destinationOffice() {
      return this.getRelationships(
        this.getStockMovement,
        this.getStockMovement.data.relationships['destination-office'].data?.id
      );
    },
    getOfficeLabel() {
      const mapOffice = {
        stockist: 'Stockist',
        member: 'Mitra',
        pusat: 'Pusat',
        'stockist-center': 'Stockist Center',
        pelayanan: 'Kantor Pelayanan',
      };
      return mapOffice[this.viewAs];
    },
  },
  methods: {
    dayjs,
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
