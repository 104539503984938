<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="space-y-4" v-else>
      <DetailPengiriman view-as="stockist-center" />
      <DetailKemasan view-as="stockist-center" />
      <DetailPengeluaranCard />

      <div class="pt-5">
        <div class="flex justify-between">
          <div>
            <div class="flex space-x-2">
              <base-button
                color="white"
                v-if="!getStockMovement.data.attributes.is_shipped"
                @click="cancelStockMovementConfirmation = true"
              >
                Batalkan Faktur Kemasan
              </base-button>
            </div>
          </div>
          <div>
            <base-button
              v-if="!getStockMovement.data.attributes.is_shipped"
              @click="openDeliveryModal"
            >
              Kirim!
            </base-button>
            <base-button color="white" :to="indexPath"> Kembali </base-button>
          </div>
        </div>
      </div>

      <!-- Detail Koli -->
      <modal-detail-koli
        :visible="openDetail"
        :packet="detailKoli"
        @close="openDetail = !openDetail"
      />

      <!-- MODAL VALIDASI KEMASAN-->
      <modal-validasi-pengiriman
        v-model="validateConfirmation"
        :data-pengiriman="dataPengiriman"
        @close="() => (validateConfirmation = !validateConfirmation)"
      />

      <!-- MODAL BATAL FAKTUR PENGEMASAN -->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="cancelStockMovementConfirmation"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <form @submit.prevent="cancelStockMovement">
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <!-- Heroicon name: outline/check -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Batalkan Faktur Pengemasan
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Sudah ada faktur pengemasan. Anda yakin ingin
                        membatalkan?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                  <base-button fullwidth type="submit"> Ya </base-button>
                  <base-button
                    fullwidth
                    color="white"
                    @click="cancelStockMovementConfirmation = false"
                    type="button"
                  >
                    Tutup
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="getStockMovement.data.attributes.code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="printAllKoli"
    >
      <LayoutPrintStockMovement
        slot="pdf-content"
        :stockMovements="getStockMovement.data"
      />
    </vue-html2pdf>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import Loading from '@/components/Loading.vue';
// import BaseModal from '@/components/base/BaseModal';
import VueHtml2pdf from 'vue-html2pdf';
import LayoutPrintStockMovement from '@/layouts/LayoutPrintStockMovement.vue';
import ModalDetailKoli from '@/components/pengiriman/modal-detail-koli.vue';
import ModalValidasiPengiriman from '@/components/pengiriman/modal-validasi-pengiriman.vue';
import DetailPengiriman from '@/components/stock-movement/stock-movement-detail.vue';
import DetailKemasan from '@/components/pengiriman/detail-kemasan.vue';
import DetailPengeluaranCard from '@/components/pengiriman/detail-pengeluaran-card.vue';

export default {
  name: 'TambahPengiriman',
  components: {
    Loading,
    DetailKemasan,
    VueHtml2pdf,
    LayoutPrintStockMovement,
    DetailPengiriman,
    ModalDetailKoli,
    ModalValidasiPengiriman,
    DetailPengeluaranCard,
  },
  props: {
    indexPath: String
  },
  data() {
    return {
      openDetail: false,
      validateConfirmation: false,
      cancelStockMovementConfirmation: false,
      dataPengiriman: {
        no_resi: null,
        taken_by: null,
        expedition: null,
      },
      selectedExpedition: {},
      selectedExpeditionDetail: {},
      detailKoli: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetchStockMovement({
        id: this.$route.params.id,
        include:
          'destination-office,origin-office,order,order.destination-office,order.province,order.city,order.district',
      }).then((response) => {
        const stockMovement = response.data.data;
        if (stockMovement.attributes.is_shipped) {
          this.dataPengiriman.no_resi =
            stockMovement.attributes.airwaybill_number;
          this.dataPengiriman.taken_by = stockMovement.attributes.taken_by;
        }
      });
    }
    this.fetchOfficeExpeditionsByOffice({
      office_id: StorageService.getUser().office_id,
      params: {
        include: 'expedition,expedition-detail',
      },
    });
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'stock_movements/getLoading',
      getStockMovement: 'stock_movements/getStockMovement',
      getExpeditionDetails: 'expedition_details/getExpeditionDetails',
      getExpeditions: 'expeditions/getExpeditions',
    }),
    getOrder() {
      return this.getRelationships(
        this.getStockMovement,
        this.getStockMovement.data.relationships.order.data.id
      );
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchExpeditions: 'expeditions/fetchExpeditions',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
      fetchExpeditionDetails: 'expedition_details/fetchExpeditionDetails',
      fetchExpeditionDetailsByExpedition:
        'expedition_details/fetchExpeditionDetailsByExpedition',
      sendStockMovement: 'stock_movements/sendStockMovement',
      deleteStockMovement: 'stock_movements/deleteStockMovement',
      updateStockMovement: 'stock_movements/updateStockMovement',
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
    }),
    async openDeliveryModal() {
      // await this.fetchExpeditions();
      this.validateConfirmation = true;
    },
    changeExpedition() {
      this.fetchExpeditionDetailsByExpedition({
        id: this.selectedExpedition.id,
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    detailModal(koli) {
      this.openDetail = !this.openDetail;
      this.detailKoli = koli;
    },
    validate() {
      let payload;
      if (
        this.getStockMovement.data.attributes.shipment_type === 'delivery'
      ) {
        payload = {
          data: {
            type: 'stock-movements',
            id: this.getStockMovement.data.id,
            attributes: {
              airwaybill_number: this.dataPengiriman.no_resi,
            },
            relationships: {
              // todo: ganti jadi expedition-details
              'expedition-detail': {
                data: {
                  type: 'expedition-details',
                  id: this.selectedExpeditionDetail.id,
                },
              },
            },
          },
        };
      } else if (
        this.getStockMovement.data.attributes.shipment_type === 'pickup'
      ) {
        payload = {
          data: {
            type: 'stock-movements',
            id: this.getStockMovement.data.id,
            attributes: {
              taken_by: this.dataPengiriman.taken_by,
            },
          },
        };
      }
      this.sendStockMovement(payload).then((response) => {
        if (response) {
          setTimeout(() => {
            this.$refs.printAllKoli.generatePdf();
            const self = this;
            // kalo udah print, redirect ke list pengiriman
            const waitForEl = setInterval(() => {
              const el = document.querySelector(
                'div.vue-html2pdf > section.pdf-preview > button'
              );
              if (el) {
                el.onclick = function () {
                  self.$router.push(this.indexPath);
                };
                clearInterval(waitForEl);
              }
            }, 100);
          }, 0);
        }
        this.openDetail = false;
      });
    },
    cancelStockMovement() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.getStockMovement.data.id,
          attributes: {
            is_valid_for_shipment: false,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.$router.push(this.indexPath);
        }
      });
    },
  },
};
</script>
