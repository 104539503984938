<template>
  <BaseCard
    title="Informasi Barang Keluar"
    description="Daftar barang yang akan dikirim "
  >
    <Datatable :paginated="false" :footer="false" class="mt-4">
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              KODE BARANG
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              NAMA BARANG
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              JUMLAH
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              HARGA
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              HARGA TOTAL
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
          <tr
          class="bg-white"
          :class="[index % 2 == 0 ? 'zebra-print' : null]"
          v-for="(barang, index) in products"
          :key="index"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            {{ barang.attributes.product_code }} {{ barang.type === 'order-detail-bonuses' ? '(BONUS)' : '' }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            {{ barang.attributes.product_name }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ (barang.type === 'order-details' ? barang.attributes.product_qty : barang.attributes.bonus_qty) | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-900"
          >
            {{ (barang.type === 'order-details' ? barang.attributes.product_price : 0) | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-900"
          >
            {{ (barang.type === 'order-details' ? barang.attributes.total_price : 0) | toCurrency }}
          </td>
        </tr>
      </template>
    </Datatable>
  </BaseCard>
</template>
<script>
import { mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';

export default {
  name: 'InformasiBarangKeluar',
  components: {
    Datatable,
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    products() {
      return [
        ...this.getIncludedByType(this.getOrder, 'order-details'),
        ...this.getIncludedByType(this.getOrder, 'order-detail-bonuses')
      ]
    }
  },
};
</script>
