<template>
  <BaseCard
    v-if="getStockMovement.data.attributes.is_shipped"
    title="Informasi Pengeluaran"
    description="Detail Pengeluaran yang akan dikirim ke Stockist"
    class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
  >
    <div
      v-if="
        getRelationships(
          getStockMovement,
          getStockMovement.data.relationships['order'].data?.id
        )?.attributes?.order_shipment === 'delivery'
      "
    >
      <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
        <div class="sm:col-span-12">
          <div class="field-inset-default w-full bg-gray-100">
            <label for="kode_po" class="block text-xs font-bold text-gray-700">
              Nomor Resi
            </label>
            <p
              class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
            >
              {{ getStockMovement.data.attributes.code }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        getRelationships(
          getStockMovement,
          getStockMovement.data.relationships['order'].data?.id
        )?.attributes?.order_shipment === 'pickup'
      "
    >
      <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
        <div class="sm:col-span-12">
          <base-input
            label="Diambil Oleh"
            name="taken_by"
            id="taken_by"
            inset
            disabled
            :value="getStockMovement.data.attributes.taken_by"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DetailPengeluaranCard',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
    }),
  },
  components: {
    // Your child components here
  },
  methods: {
    getRelationships(resource, id) {
      let data = resource.included.find(function (el) {
        return el.id === id;
      });
      return data;
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
